<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <el-page-header @back="$router.go(-1)" content="订单详情"></el-page-header>
    <div class="info-box">
      <el-descriptions title="用户信息">
        <el-descriptions-item label="订单编号">{{ orderInfo.orderNo }}</el-descriptions-item>
        <el-descriptions-item label="订单类型">{{ orderInfo.orderType | filterOrderType(that) }}</el-descriptions-item>
        <el-descriptions-item label="所属酒店">{{ orderInfo.hotelName }}</el-descriptions-item>
        <el-descriptions-item label="下单时间">{{ orderInfo.createTime }}</el-descriptions-item>
        <el-descriptions-item label="订单状态">{{ orderInfo.orderStatus | filterOrderStatus(that) }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="info-box">
      <el-descriptions title="会员信息">
        <el-descriptions-item label="会员编号">{{ orderInfo.userId }}</el-descriptions-item>
        <el-descriptions-item label="会员昵称">{{ orderInfo.reserveName }}</el-descriptions-item>
        <el-descriptions-item label="手机号码">{{ orderInfo.reserveMobile }}</el-descriptions-item>
        <el-descriptions-item label="注册时间">{{  }}</el-descriptions-item>
        <el-descriptions-item v-if="orderInfo.reserveAddress" label="收货地址">{{ orderInfo.reserveAddress }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="info-box">
      <el-descriptions title="商品信息">
        <el-descriptions-item label="商品金额">{{ orderInfo.productTotalPrice }}</el-descriptions-item>
        <el-descriptions-item label="运费金额"></el-descriptions-item>
        <el-descriptions-item label="优惠金额"></el-descriptions-item>
        <el-descriptions-item label="支付方式">{{ orderInfo.paymentMethod | filterPayMethod(that) }}</el-descriptions-item>
        <el-descriptions-item label="应付金额">{{ orderInfo.orderTotalPrice }}</el-descriptions-item>
      </el-descriptions>
      <el-table ref="table" :data="orderInfo.orderItems" :stripe="true" border>
        <el-table-column label="商品信息">
          <template slot-scope="scope">
            <el-row type="flex" justify="space-between">
              <el-image class="width-100" :src="scope.row.picture"></el-image>
              <div>
                <p>商品编号：{{ scope.row.productId }}</p>
                <p>商品名称：{{ scope.row.title }}</p>
                <p>商品类型：{{  }}</p>
              </div>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="价格（元）"></el-table-column>
        <el-table-column prop="quantity" label="数量"></el-table-column>
        <el-table-column prop="deductionAmount" label="小计"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { getDict } from "@/common/js/common";
import { getPage } from "@/api/shop/order/order";
export default {
  name: 'orderDetail',
  data() {
    return {
      crumbs: new Map([
          ['特惠商城'], ['订单中心'], ['订单管理', '/preferential_mall/order_manage'], ['订单详情']
      ]),
      that: this,
      orderInfo: {
        orderNo: '',
        orderType: '',
        hotelName: '',
        createTime: '',
        orderStatus: '',
        userId: '',
        reserveName: '',
        reserveMobile: '',
        reserveAddress: '',
        productTotalPrice: 0,
        paymentMethod: '',
        orderTotalPrice: 0,
        orderItems: []
      }
    }
  },
  computed: {
    ...mapState(['dictData'])
  },
  mounted() {
    getDict(['order-status', 'order-type', 'payment-method'])
    this.getOrderDetail()
  },
  methods: {
    // 获取订单详情
    getOrderDetail() {
      const query = `?page=1&limit=1`,
            params = { id: this.$route.query.id, dataScope: 'MALL' };
      getPage(query, params).then(({ success, records }) => {
        if (!success || records.length === 0) return
        for (const k in records[0]) k in this.orderInfo && (this.orderInfo[k] = records[0][k])
        this.orderInfo.reserveAddress = records[0].subOrderInfo.reserveAddress
      })
    }
  },
  filters: {
    filterOrderStatus(val, that) {
      if (val && that.dictData['order-status']) return that.dictData['order-status'][val] || val
    },
    filterOrderType(val, that) {
      if (val && that.dictData['order-type']) return that.dictData['order-type'][val] || val
    },
    filterPayMethod(val, that) {
      if (val && that.dictData['payment-method']) return that.dictData['payment-method'][val] || val
    }
  }
}
</script>
<style lang="scss" scoped>
.cont {
  .info-box{ background: #FFFFFF; padding: 20px }
  .el-table{
    .el-image { flex-shrink: 0; margin-right: 20px }
  }
}
</style>
