import request from "@/api/service";

const api = {
   page: '/order/getPage',
}

export function getPage(query, params) {
   return request({
      url: api.page + query,
      method: 'POST',
      data: params
   })
}
